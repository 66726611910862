/* Import Jersey10 font */
@font-face {
    font-family: 'Jersey10';
    src: url('./fonts/Jersey10-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Import Kanit font */
@font-face {
    font-family: 'Kanit';
    src: url('./fonts/Kanit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.fp-section {
    width: 100vw !important; /* Ensure full viewport width */
    max-width: 100vw !important; /* Prevent any max-width constraints */
    padding: 0 !important; /* Remove any padding */
    margin: 0 !important; /* Remove any margin */
    box-sizing: border-box; /* Include padding and border in the width */
  }
  
  .fp-tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

/* General App styling */
body, html {
    font-family: 'Kanit', sans-serif; /* Set Kanit as the default font */
    background-color: #1e1e1e;
    color: white;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}


/* Styling for the title text (used for headings like About and Support) */
.page-title {
    font-size: 80px; /* Same size as the Zero Day text */
    margin: 0;
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 font */
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center; /* Center the title */
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7); /* Add drop shadow */

}

/* Apply font size and styling to the questions (strong tags) */
.qa-section p strong {
    font-size: 22px; /* Slightly larger size for the strong text */
    font-family: 'Jersey10', sans-serif; /* Ensure questions use Jersey10 or default font */
}

/* Apply font size and Kanit font to the answers (text after strong tags) */
.qa-section p {
    font-size: 14px; /* Adjust this size to make the answers larger */
    line-height: 1.6; /* Optional: Improve readability */
}

/* Target the answers specifically */
.qa-section p {
    font-family: 'Kanit', sans-serif; /* Apply Kanit font to the entire paragraph */
}

/* Optionally, target just the answers (not the strong text) */
.qa-section p strong + br + span {
    font-family: 'Kanit', sans-serif; /* Apply Kanit font to the answers after the strong text */
    font-size: 20px; /* Adjust this size as needed */
}



/* Center the main container and limit the width */
/* General App styling */
.app {
    text-align: center;
    background-color: #282c34; /* Dark background */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Jersey10', sans-serif; /* Use Jersey 10 font */
    width: 100vw; /* Ensure full viewport width */
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }
  

/* Styling for the header */
.header {
    width: 100%;
    background-color: #333333;
    padding: 10px 15px; /* Reduce padding for a smaller header */
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: 'Jersey10', sans-serif;
    text-align: center;
    left: 0;
}

.header nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.header nav ul li {
    margin: 0 10px; /* Reduce margin between links */
}

.header nav ul li a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: 'Jersey10', sans-serif;
    font-size: 24px; /* Reduce font size for a more compact appearance */
    transition: color 0.3s ease;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.game-title {
    margin: 0;
    text-align: center; /* Center the title */
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.9); /* Increase opacity for less transparency */
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 for the Zero Day text */
}

.main-title {
    font-size: 80px; /* Larger size for "Zero Day" */
    text-transform: uppercase;
    display: block; /* Ensures the title is on its own line */
    line-height: 1; /* Adjusts space between lines */
    margin-bottom: -10px; /* Negative margin to pull "Humanity's Last Hope" closer */
}

.sub-title {
    font-size: 40px; /* Smaller size for "Humanity's Last Hope" */
    text-transform: uppercase;
    display: block; /* Ensures the subtitle is on its own line */
    line-height: 1; /* Ensures close spacing */
    margin-top: 0; /* Removes any additional margin at the top */
}




/* Promotional content container */
.promo-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap; /* Ensure responsiveness */
    text-align: center; /* Center the content */
}

/* Image for promotion */
.promo-image {
    height: 200px; /* Smaller image size */
    margin-right: 20px;
    border-radius: 8px; /* Make image corners rounded */

  }

  .promo-image-small {
    width: 20%; /* Size for smaller images like gifs */

  }

  .promo-image-large {
    width: 60%; /* Size for smaller images like gifs */

  }
  
  .section-placeholder {
    margin: 40px 0;
  }

/* Text content next to the image */
.promo-text {
    max-width: 600px;
    text-align: center; /* Center promotional text */
    font-family: 'Kanit', sans-serif; /* Use Kanit for promotional text */
}

/* Text paragraph */
.promo-text p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
}

/* Download button/link */
.download-link {
    display: inline-block;
    padding: 15px 30px;
    background-color: black; /* Black background */
    color: white; /* White text */
    text-decoration: none;
    font-size: 28px; /* Match the size of the submit button */
    border-radius: 15px; /* Rounded corners */
    font-weight: bold;
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 for the button text */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 30px;
}

.download-link:hover {
    background-color: #333; /* Slightly lighter black on hover */
    transform: translateY(-2px); /* Add a small lift effect */
}

.download-link:active {
    background-color: #000; /* Darker black on click */
    transform: translateY(0); /* Remove lift effect on click */
}

/* HR SKA DET VARA

/* Apply Jersey font to the soundtrack title */
.soundtrack-title {
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 for the button text */
    font-size: 34px;
    text-align: center;
    color: white;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7); /* Add drop shadow */

}

/* Style the song list */
.song-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

/* Apply Kanit font to song list items */
.song-item {
    font-family: 'Jersey10', sans-serif; 
    cursor: pointer;
    color: white;
    font-size: 20px;
    transition: color 0.3s ease;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Add drop shadow */

}

/* Highlight the selected song */
.song-item.selected {
    color: #FFD700; /* Gold color for the selected song */
    font-weight: bold;
}

/* Hover effect for song items */
.song-item:hover {
    color: #CCCCCC;
}

.instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .instagram-feed img {
    width: 100%;
    max-width: 150px;
    margin: 5px;
  }
  
  .instagram-loading {
    text-align: center;
    color: white;
  }
  

  

/* About text section */
.about-text {
    margin-top: 40px;
    padding: 20px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: 'Kanit', sans-serif; /* Use Kanit for the about text */
    text-align: center; /* Center about text */
}

.about-text p {
    font-size: 18px;
    line-height: 1.6;
    color: white;
}

.about-text a {
    color: #61dafb;
    text-decoration: none;
}

.about-text a:hover {
    text-decoration: underline;
}

.footer {
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 20px; /* Reduce padding for a smaller footer */
    font-family: 'Jersey10', sans-serif;
    position: relative;
    left: 0;
    bottom: 0;
    font-size: 14px; /* Reduce font size for footer text */
}

.footer a {
    color: white;
    margin: 0 8px; /* Slightly reduce margin between links */
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .promo-content {
        flex-direction: column;
        text-align: center;
    }

    .promo-image {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .promo-text {
        text-align: center;
    }

 /* Header adjustments */
    .header {
        padding: 5px 10px; /* Further reduce padding */
    }

    .header nav ul li a {
        font-size: 16px; /* Reduce font size for a more compact look */
        margin: 0 5px; /* Reduce space between links */
    }

    /* Footer adjustments */
    .footer {
        padding: 8px 10px; /* Reduce padding to make the footer smaller */
        font-size: 12px; /* Reduce font size for footer text */
    }

    .footer a {
        margin: 0 5px; /* Reduce margin between links in the footer */
    }
}

/* Section Header Styling */
.section-header {
    font-size: 40px;
    font-weight: bold;
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 font */
    margin-top: 40px;
    text-align: center; /* Center the headers */
    color: #ffffff; /* Optional: Change color for emphasis */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add drop shadow */
}


/* Styling for the content sections (promo-text) */
.promo-text {
    max-width: 600px;
    text-align: center; /* Center promotional text */
    font-family: 'Kanit', sans-serif; /* Use Kanit for promotional text */
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
}


.feedback-form {
    display: flex;
    flex-direction: column;
}

/* Email input and feedback textarea styling */
.feedback-input,
.feedback-textarea {
    width: 80%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 15px; /* Add rounded corners */
    font-size: 18px;
    font-family: 'Kanit', sans-serif; /* Use Kanit for input text */
    color: #333;
}

.feedback-textarea {
    resize: vertical;
    min-height: 150px;
}

/* Submit button styling */
.submit-button {
    padding: 15px 30px;
    margin-bottom: 20px;
    background-color: black; /* Black background */
    color: white; /* White text */
    font-size: 18px;
    font-weight: bold;
    font-family: 'Jersey10', sans-serif; /* Use Jersey10 for the button text */
    border: none;
    border-radius: 15px; /* Add rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
    transform: translateY(-2px); /* Add a small lift effect */
}

.submit-button:active {
    background-color: #000; /* Darker black on click */
    transform: translateY(0); /* Remove lift effect on click */
}

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #1e1e1e;
}

.privacy-policy-container p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #444;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between icons */
    margin-top: 10px;
}

.social-icon {
    width: 30px; /* Set the width */
    height: 30px; /* Set the height */
    transition: transform 0.3s ease; /* Add a hover effect */
}

.social-icon:hover {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.social-icon img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}
